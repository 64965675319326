import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import { AppContext } from '../components/AppContext'
import ReactDOM from 'react-dom'
import Search from '../components/Search'

class DocPage extends React.Component {
  static contextType = AppContext
  notFoundPageSearchPlace = null

  state = {
    notFoundPageSearchPlace: null,
  }

  componentDidMount() {
    this.renderSearch()
  }

  renderSearch = () => {
    const { theme } = this.context
    if (theme === 'aimylogic' && document.querySelector('.page-404')) {
      this.setState({
        notFoundPageSearchPlace: document.querySelector('.page-404'),
      })
    }
  }

  render() {
    const post = this.props.data.markdownRemark
    const postTitle = post.headings?.[0]?.value
    const { language, algoliaCredentials } = this.context
    const { notFoundPageSearchPlace } = this.state

    return (
      <>
        <Seo
          title={post.frontmatter?.title || postTitle}
          description={post.frontmatter?.description || post.excerpt}
          language={language}
        />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        {algoliaCredentials &&
          notFoundPageSearchPlace &&
          ReactDOM.createPortal(<Search />, notFoundPageSearchPlace)}
      </>
    )
  }
}

export default DocPage

export const pageQuery = graphql`
  query DocBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
      }
      headings(depth: h1) {
        value
      }
    }
  }
`
